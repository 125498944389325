//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoCheckmarkCircleSharp, IoHourglass, IoCamera, IoHome, IoPencil, IoBusiness, IoInformationCircle, IoCheckmarkCircleOutline, IoAttach, IoOpenOutline, IoLinkOutline } from 'react-icons/io5';
import Form from 'react-bootstrap/Form';

// da fare:
// - modale di conferma quando si clicca sul bottone

//logic
import { usePostProduction } from './usePostProduction';
import moment from 'moment';
import DayNavigator from 'components/DayNavigator';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { useAuthValue } from 'context/AuthContext';
import { Accordion } from 'react-bootstrap';
import { useState } from 'react';
import InputUpload from 'components/InputUpload';
import useUpload from 'hooks/useUpload';
import axios from 'axios';
import { toast } from 'react-toastify';
import RenderTabs from 'components/RenderTabs';
import { uploadsField } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { selectUploads } from 'store/selectors';

function EditedPhotoInfo({
    next, 
    previous, 
    day,
    taskEdited,
    isLoading
}) {

    const { user } = useAuthValue();
    const percentage = taskEdited * 100 / (user.editor.content_x_day ?? 1);

    return (
        <>
        {next && previous && <DayNavigator
            next={next}
            previous={previous}
            day={day}
        />}
        <div className="card mb-2">
            <div className="card-body text-center pt-1 p-2 placeholder-glow">
                <div className='text-white mb-1 text-14'>Contenuti editati:</div>
                <div className={`progress position-relative ${isLoading ? 'w-100 placeholder' : ''}`}>
                    <div 
                        className={`progress-bar bg-${percentage >= 100 ? 'success' : (percentage < 50 ? 'danger' : 'warning')}`}
                        role="progressbar" 
                        style={{width: isLoading ? 0 : (percentage > 100 ? 100 : percentage) + '%'}} 
                        aria-valuenow="taskEdited" 
                        aria-valuemin="0" 
                        aria-valuemax="100"
                    ><span className="progress-text">{taskEdited}/{user.editor.content_x_day}</span></div>
                </div>
            </div>
        </div>
        </>
    );
}

function PrenotationItem({
    task,
    index,
    handleChange,
    handleSave,
    pageRole,
    completed = false,
}) {

    const isEditable = task.status === 'WAITING_PHOTOGRAPHER' || (
        task.status === 'WAITING_EDITOR' &&
        moment().isBefore(moment(task.date_in).set({
            hour: 23,
            minute: 59,
            second: 59,
        })
        )
    );

    const isMatterport = task?.order_item?.product?.edit_action === 'MATTERPORT';
    const matteportLink = task?.order_item?.product?.parent_product?.edit_action === 'MATTERPORT' ? task?.order_item?.order.appointment?.building?.matterport_link : null;
    console.log(matteportLink);

    return (
        <Form className="card mb-2" onSubmit={(e) => handleSave(e, index)}>
            <div className="card-header">
                <h3 className='mb-0'>
                    {moment(task.order_item.order.appointment.estimated_start_at).format('DD/MM/YYYY HH:mm')}
                    <span className='mx-2'>|</span> RIF. {task.order_item.order.appointment.building.reference}
                </h3>
                <div className="text-14">
                    <IoBusiness className="vertical-align-n2" /> {task.order_item.order.organization.display_name}
                </div>
                {task.status !== 'WAITING_PHOTOGRAPHER' && <div className="text-14">
                    {'Consegnato: '}
                    {pageRole === 'editor' ? task.order_item.order.appointment.photographer.user.display_name : 'Da te'} il {moment(task.date_in).format('DD/MM/YYYY HH:mm')}
                </div>}
                {task.status === 'DONE' && <div className="text-14">
                    {'Editing: '}
                    {pageRole === 'photographer' ? task.editor?.user.display_name : 'Da te'} il {moment(task.date_out).format('DD/MM/YYYY HH:mm')}
                </div>
                }
            </div>
            <div className="card-body p-0 pb-1">
                <ul className="listview image-listview no-after no-border">
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header><IoHome className="vertical-align-n2" /> Dettagli immobile:</header>
                                    {task.order_item.order.appointment.building.address.full}<br />
                                    Proprietario: {task.order_item.order.appointment.building.owner_last_name} {task.order_item.order.appointment.building.owner_first_name}
                                </div>
                            </div>
                        </div>
                    </li>
                    {(task.order_item.is_priority || task.order_item.product.description !== "Servizio fotografico") && (
                        <li>
                            <div className="item pt-0">
                                <div className="in">
                                    <div>
                                        <header><IoCamera className="vertical-align-n2" /> Servizio:</header>
                                        {task.order_item.product.description}
                                        {task.order_item.is_priority ? <>
                                            <br />
                                            <span className='text-warning'>({task.order_item.product.priority_label})</span>
                                        </> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                    {pageRole === 'editor' ? <>
                        {task.note_in && (<li>
                            <div className="item pt-0">
                                <div className="in">
                                    <div>
                                        <header><IoPencil className="vertical-align-n2" /> Indicazioni per il postproduttore:</header>
                                        {task.note_in}
                                    </div>
                                </div>
                            </div>
                        </li>
                        )}
                        {completed ? <>
                            {task.note_out && (<li>
                                <div className="item pt-0">
                                    <div className="in">
                                        <div>
                                            <header><IoPencil className="vertical-align-n2" /> Indicazioni del postproduttore:</header>
                                            {task.note_out}
                                        </div>
                                    </div>
                                </div>
                            </li>)}
                        </> : <>
                            <li>
                                <div className="item my-2 text-gray pt-0">
                                    <div className="in">
                                        <div>
                                            <IoInformationCircle className="vertical-align-n2" /> <b>{task.quantity}{" "}</b>
                                            elementi da elaborare su HD. Una volta completate, clicca il bottone.
                                            {matteportLink  ? <>
                                                <br />
                                                <IoLinkOutline className="vertical-align-n2" />{" "}
                                                <a href={matteportLink} className="active" target="_blank" >Link al matterport</a>
                                            </> : ''}
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="item pt-0">
                                    <div className="form-group boxed pt-0">
                                        <header><IoPencil className="vertical-align-n2" /> Note sul servizio del fotografo (opzionale):</header>
                                        <textarea
                                            rows="2"
                                            className="form-control mt-1"
                                            name="note_out"
                                            value={task.note_out || ''}
                                            onChange={e => handleChange(e, index)}
                                        />
                                    </div>
                                </div>
                            </li>
                        </>}
                    </> : ''}
                    {pageRole === 'photographer' ? <>
                        {isMatterport ? <>
                            <li>
                                <div className='item pt-0'>
                                    <div className="form-group boxed pt-0">
                                        <header><IoInformationCircle className="vertical-align-n2" /> Link matterport:</header>
                                        <Form.Control
                                            className='mt-1'
                                            type="text"
                                            required
                                            name="note_in"
                                            value={task.note_in || ''}
                                            onChange={e => handleChange(e, index)}
                                            disabled={!isEditable}
                                        />
                                    </div>

                                </div>
                            </li>
                        </> : <>
                            <li>
                                <div className="item pt-0">
                                    <div className="form-group boxed pt-0">
                                        <header><IoPencil className="vertical-align-n2" /> Indicazioni per il postproduttore (opzionale):</header>
                                        <textarea
                                            rows="2"
                                            className="form-control mt-1"
                                            name="note_in"
                                            value={task.note_in || ''}
                                            onChange={e => handleChange(e, index)}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='item pt-0'>
                                    <div className="form-group boxed pt-0">
                                        <header><IoInformationCircle className="vertical-align-n2" /> Indica il numero di foto/video da elaborare dopo averle caricate su HD:</header>
                                        <Form.Control
                                            className='mt-1'
                                            type="number"
                                            required
                                            name="quantity"
                                            value={task.quantity || ''}
                                            onChange={e => handleChange(e, index)}
                                            disabled={!isEditable}
                                        />
                                    </div>

                                </div>
                            </li>
                        </>}
                    </> : ''}
                </ul>
            </div>
            {((pageRole === 'photographer' && isEditable) || (pageRole === 'editor' && !completed)) && <div className="card-footer">
                <Button
                    className={`btn-block my-1 ${task.isLoading ? 'disabled' : ''} ${task.needSave ? 'blinking-element' : ''}`}
                    variant="primary"
                    type="submit"
                >
                    <SpinnerForButton show={task.isLoading} />
                    {pageRole === 'editor' ? 'Foto elaborate caricate su HD' : (
                        completed ? 'Aggiorna informazioni' : (isMatterport ? 'Invia' : 'Invia al post-produttore')
                    )}
                </Button>
            </div>}
        </Form>
    );
}

function PlanItem({
    task,
    onTaskCompleted
}) {

    const [activeTab, setActiveTab] = useState('information');
    const handleTabChange = (tab) => setActiveTab(tab);
    const [isInProgress, setIsInProgress] = useState(false);
    const uploads = useSelector(selectUploads);
    const ukey = 'task_' + task.id;
    const plan_3d = uploads[ukey];
    const [ uploadFile ] = Object.values(plan_3d ?? {}) ?? [];

    const handleConfirm = () => {
        setIsInProgress(true);
        axios.put(`tasks/${task.id}`, {
            update_role: 'editor',
            delivery_file_path: uploadsField(plan_3d, 'path')
        })
        .then(() => {
            toast.success('Task completato con successo');
            onTaskCompleted(task.id);
        });
    }

    return (<>
        <Accordion>
            <Accordion.Item eventKey={`plan-${task.id}`}>
                <Accordion.Header>
                    <div className='py-1'>
                        <h3 className="mb-0">
                            {moment(task.created_at).format('DD/MM/YYYY HH:mm')} <span className="mx-2">|</span>{task.order_item.product.description}
                        </h3>
                        <div className="text-14 d-flex align-items-center lh-16 mt-05 mb-05">
                            <IoBusiness className="vertical-align-n2 d-inline-block text-16 m-0 me-05" /> {task.order_item.order.organization.display_name}
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className='p-0'>
                    <div>
                        <ul className="nav nav-tabs lined" role="tablist" style={{ backgroundColor: '#222222' }}>
                            <li className="nav-item">
                                <a
                                    className={`nav-link fw-bold ${activeTab === 'information' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('information')}
                                    data-bs-toggle="tab"
                                    role="tab"
                                >
                                    INFORMAZIONI
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link fw-bold ${activeTab === 'delivery' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('delivery')}
                                    data-bs-toggle="tab"
                                    role="tab"
                                >
                                    CONSEGNA
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-1">
                            <div className={`tab-pane fade ${activeTab === 'information' ? 'show active' : ''}`} id="information" role="tabpanel">

                                <ul className="listview image-listview no-border">
                                    <li>
                                        <div className="item">
                                            <div className="in">
                                                <div>
                                                    <header>
                                                        <IoInformationCircle /> Specifiche:
                                                    </header>
                                                    {task.metadata.instructions}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="in">
                                                <div>
                                                    <header>
                                                        <IoAttach /> File di riferimento:
                                                    </header>
                                                    <a 
                                                        className='active me-1'
                                                        href={task.plan.instructions_file.url}
                                                        target="_blank"
                                                    >
                                                        {task.plan.instructions_file.name} <IoOpenOutline className='vertical-align-n2' />
                                                    </a> 
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'delivery' ? 'show active' : ''}`} id="delivery" role="tabpanel">
                                {task?.plan?.delivery_file?.url ? (
                                    <ul className="listview image-listview no-border">
                                        <li>
                                            <div className="item">
                                                <div className="in">
                                                    <div>
                                                        <header>
                                                            <IoAttach /> File consegnato:
                                                        </header>
                                                        <a 
                                                            className='active me-1'
                                                            href={task?.plan?.delivery_file?.url}
                                                            target="_blank"
                                                        >
                                                            {task?.plan?.delivery_file?.name} <IoOpenOutline className='vertical-align-n2' />
                                                        </a> 
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                <div className='pt-1 pb-2 section'>
                                    <InputUpload
                                        gkey={ukey}
                                        label={`Planimetria 3D`}
                                        limit={1}
                                        hideLabelOnLimit={false}
                                    />
                                    <Button
                                        className={`btn-block mt-2 btn btn-primary text-uppercase ${(uploadFile?.completed && !isInProgress) ? '' : 'disabled'}`}
                                        type="button"
                                        onClick={handleConfirm}
                                    >
                                        <SpinnerForButton show={isInProgress}/>
                                        Invia al cliente
                                    </Button>

                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>);
}

function RenderItem({
    task,
    onTaskCompleted
}) {

    const [orderData, setOrderData] = useState();

    const handleLoadOrderData = () => {
        axios.get(`orders/${task.order_item.order.id}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => {
            setOrderData(res.data);
        });
    }

    return (<>
        <Accordion>
            <Accordion.Item eventKey={`render-${task.id}`}>
                <Accordion.Header>
                    <div className='flex-fill py-1'>
                        <h3 className="mb-0">
                            {moment(task.created_at).format('DD/MM/YYYY HH:mm')} <span className="mx-2">|</span>Render {task.metadata.is_custom ? 'personalizzato' : task.order_item.product.description}
                        </h3>
                        <div className="text-14 d-flex align-items-center lh-16 mt-05 mb-05">
                            <IoBusiness className="vertical-align-n2 d-inline-block text-16 m-0 me-05" /> {task.order_item.order.organization.display_name}
                        </div>
                    </div>
                    {!!task?.render?.badge_new && <div>
                        <span className="badge badge-primary">NEW</span>
                    </div>}
                </Accordion.Header>
                <Accordion.Body 
                    className='p-0'
                    onEnter={() => !orderData && handleLoadOrderData()}
                >
                    <RenderTabs 
                        orderData={orderData}
                        setOrderData={setOrderData}
                        onTaskCompleted={onTaskCompleted}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>);
}

function TaskList({
    tasks,
    handleChange,
    handleSave,
    pageRole,
    completed,
    onTaskCompleted
}) {


    if (!tasks) {
        return <>
            <div className="card mb-2">
                <div className="card-body text-center text-white"><span className="spinner-border"></span></div>
            </div>
        </>;
    }

    if (tasks.length === 0) {
        return <>
            <div className="card mb-2">
                <div className="card-body text-center text-white">{completed ? 'Nessun servizio trovato' : 'Al momento non ci sono servizi.'}</div>
            </div>
        </>;
    }
    
    return tasks.map((task, index) => (
        task.order_item.product.type === 'PLAN' ? 
            <PlanItem
                task={task} 
                key={task.id}
                onTaskCompleted={onTaskCompleted}
            /> : (
            task.order_item.product.type === 'RENDER' ? 
                <RenderItem 
                    task={task} 
                    key={task.id}
                    onTaskCompleted={onTaskCompleted}
                /> : 
                <PrenotationItem
                    key={task.id}
                    index={index}
                    task={task}
                    {...{
                        handleChange,
                        handleSave,
                        pageRole,
                        completed,
                    }}
                />
            )
    ))

}

export default function PostProduction(props) {

    const { pageRole } = props;

    const {
        tab, setTab,
        tasks,
        handleChange,
        handleSave,
        next, 
        previous, 
        day, 
        taskEdited,
        onTaskCompleted,
    } = usePostProduction(props);

    return (
        <>
            <Header pageTitle={"Consegna contenuti"} />

            <Capsule className='pb-0'>
                <div className="section mb-2">
                    <Tabs
                        activeKey={tab}
                        onSelect={tab => setTab(tab)}
                        id="tab-tasks"
                        className="mb-2 capsuled"
                    >
                        <Tab
                            eventKey={`WAITING_${pageRole.toUpperCase()}`}
                            title={<span><IoHourglass />Da {pageRole === 'photographer' ? 'consegnare' : 'editare'}</span>}
                        >
                            {pageRole === 'editor' && <EditedPhotoInfo 
                                {...{
                                    taskEdited,
                                    isLoading: !tasks
                                }}
                            />}
                           <div className='overflow-hidden rounded'>
                            <TaskList
                                {...{
                                    tasks,
                                    handleChange,
                                    handleSave,
                                    pageRole,
                                    onTaskCompleted
                                }}
                             />
                            </div>
                        </Tab>
                        <Tab
                            eventKey={pageRole === 'editor' ? 'DONE' : "WAITING_EDITOR"}
                            title={<span><IoCheckmarkCircleSharp />{pageRole === 'photographer' ? 'Consegnati' : 'Completati'}</span>}
                        >

                            {pageRole === 'editor' && <EditedPhotoInfo 
                                {...{
                                    next, 
                                    previous, 
                                    day,
                                    taskEdited,
                                    isLoading: !tasks
                                }}
                            />}
                            <TaskList
                                {...{
                                    tasks,
                                    handleChange,
                                    handleSave,
                                    pageRole
                                }}
                                completed
                            />
                        </Tab>
                    </Tabs>
                </div>

            </Capsule>
        </>

    );
}